<template>
  <v-footer padless color="white" class="pt-16 pb-">
    <v-container style="width: 69rem">
      <v-row>
        <v-col cols="12">
          <router-link to="/" class="monestro-logo px-4">
            <img src="../../assets/monestro-logo.svg" height="25rem">
          </router-link>
        </v-col>
      </v-row>
      <v-row class="links">
        <v-col cols="12" md="3">
          <v-list dense flat class="mr-4" color="transparent">
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/for-investors`">{{ $t("footer.forInvestors") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/loan-originators/`">{{ $t("footer.loanOriginators") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/key_figures/`">{{ $t("footer.keyFigures") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/investor-protection`">{{ $t("footer.investorProtection") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/fees/`">{{ $t("footer.fees") }}</v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="3">
          <v-list dense flat class="mr-4" color="transparent">
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/about-us`">{{ $t("footer.aboutUs") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/careers`">{{ $t("footer.careers") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/media-centre`">{{ $t("footer.mediaCentre") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/blog`">{{ $t("footer.blog") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/about-us/#contact-us`">{{ $t("footer.contactUs") }}</v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="3">
          <v-list dense flat class="mr-4" color="transparent">
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/faq`">{{ $t("footer.faq") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/general-terms`">{{ $t("footer.generalTerms") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/privacy-notice`">{{ $t("footer.privacyNotice") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/reserve-policy`">{{ $t("footer.reservePolicy") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/for-loan-originators`">{{ $t("footer.forLoanOriginators") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/cookie-policy`">{{ $t("footer.cookiePolicy") }}</v-list-item>
            <v-list-item :ripple="false" :href="`https://monestro.com/${ $i18n.locale }/affiliate-programme`">{{ $t("footer.affiliateProgramme") }}</v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="3">
          <v-list dense flat color="transparent">
            <v-list-item>
              <v-btn
                icon
                :ripple="false"
                href="https://www.facebook.com/MonestroP2P"
                class="icon icon--facebook"
                target="_blank"
              >
                <v-icon large color="#3B5998">
                  mdi-facebook
                </v-icon>
              </v-btn>
              <v-btn
                icon
                href="https://ee.linkedin.com/company/monestro-com"
                target="_blank"
                :ripple="false"
                class="icon icon--linkedin"
              >
                <v-icon large color="#007BB6">
                  mdi-linkedin
                </v-icon>
              </v-btn>
              <v-btn
                icon
                :ripple="false"
                href="https://twitter.com/monestro_p2p"
                class="icon icon--twitter"
                target="_blank"
              >
                <v-icon large color="#00ACED">
                  mdi-twitter
                </v-icon>
              </v-btn>
              <v-btn
                icon
                :ripple="false"
                href="https://www.instagram.com/monestrop2p/"
                class="icon icon--instagram"
                target="_blank"
              >
                <v-icon large color="#EA2C59">
                  mdi-instagram
                </v-icon>
              </v-btn>
              <v-spacer/>
            </v-list-item>
            <v-list-item disabled>
              FSA authorised &amp; regulated
            </v-list-item>
            <v-list-item disabled>
              Permit: 4.1-1/144 (19.09.2016)
            </v-list-item>
            <v-list-item disabled>
              Data as of Feb 2020
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
}
</script>
<style scoped lang="scss">
.v-list-item {
  line-height: 1.0;
  padding-top: 0.25rem;
}
/*
.links .v-list {
  background-color: rgba(0, 0, 0, 0);
}*/
/*
.footer {
  background-color: #fff;
  padding: 120px 0 52px;

  &__content {
    padding-top: 52px;

    &--column {
      line-height: 32px;

      *,
      a {
        display: block;
        color: #0f1015;
      }
    }
  }

  .icon {
    display: inline-block;
    margin-right: 8px;
    border-radius: 50%;
    height: 32px;
    width: 32px;

    &--facebook {
      background-color: #3b5998;
    }

    &--linkedin {
      background-color: #007bb6;
    }
  }

    .layout {
      max-width: 1040px;
      width: 100%;
      margin: 0 auto;
    }
}

.device-mobile {
  .footer {
    padding: 20px 10px;

    &__content {
      padding-top: 20px;

      &--column {
        margin-bottom: 15px;
      }
    }
  }
}*/
</style>
